import React from 'react'
import AboutSection from '../Sections/AboutSection'
import Footer from '../Footer'
import '../../App.css'
import AboutUsMiddleSection from '../MiddleSection/AboutUsMiddleSection'


function About() {
  return (
    <>
      <AboutSection/>
      <AboutUsMiddleSection/>
      <Footer/>
    </>
  )
}

export default About
