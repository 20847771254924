import React from 'react'
import './AboutUsMiddle.css'
import './HomeMiddle.css'

function AboutUsMiddleSection() {
  return (
    <div className="about-page">

      <div id="about-values" className="about-erence">
        {/* About Us Section */}
        <div className="about-us">
        <h3 id="about-us" >About Us</h3>
        <p>
          Erence Developers is dedicated to delivering high-quality website development services.
          Our team of experts is passionate about creating stunning websites that meet your needs.
          Mobile development services are coming soon—stay tuned!
        </p>

        <br/>
        <br/>
        {/* Our Mission Section */}
        <h3 id="about-mission">Our Mission</h3>
        <p>
          At Erence Developers, our mission is to transform your ideas into digital experiences that engage and inspire.
          We aim to empower businesses with cutting-edge web and mobile solutions that drive growth and success.
        </p>

        <br/>
        <br/>
        {/*Our Values Section*/}
        <h3>Our Values</h3>
        <p>At Erence Developers, our values guide us in every project and interaction, ensuring we deliver the best for our clients and our community.</p>
        <ul>
          <li><strong>Innovation:</strong> At Erence Developers, we believe in the power
            of innovation to drive progress. We constantly explore new
            technologies and creative solutions to deliver cutting-edge
            digital experiences that exceed client expectations.</li>

          <li><strong>Quality:</strong> Quality is at the heart of everything we do.
            Our commitment to excellence ensures that every project
            is crafted with precision, attention to detail, and the highest
            standards of professionalism.</li>

          <li><strong>Integrity:</strong> We uphold the principles of honesty and transparency in all our
            dealings. Building trust with our clients, partners, and team members
            is fundamental to our success and the foundation of our reputation.</li>

          <li><strong>Customer-Centric Approach:</strong> Our clients are our top priority. We take the time to
            understand their needs, goals, and vision, ensuring that
            we provide personalized solutions and a seamless experience
            from start to finish.</li>

          <li><strong>Collaboration:</strong> We foster a collaborative environment where
            ideas and expertise are shared freely. By working closely with our
            clients and partners, we achieve better outcomes and create meaningful,
            impactful digital solutions.</li>

          <li><strong>Continuous Improvement:</strong> We are dedicated to continuous learning and improvement.
            Embracing feedback and staying abreast of industry trends allows
            us to adapt and grow, delivering value-driven results in an
            ever-evolving digital landscape.</li>

          <li><strong>Sustainability:</strong> We recognize the importance of sustainability and are committed to making
            responsible choices that benefit both our business and the environment.
            We aim to create digital solutions that are efficient, effective, and
            environmentally conscious.</li>
        </ul>

        <br/>
        <br/>
        <div className="about-erence">
        {/* About Erence Developers Section */}
        <h3 id="about-choose-us" >Why Choose Erence Developers?</h3>
        <p>
          At Erence Developers, we believe that every business deserves a unique digital identity.
          We don't just build websites; we craft custom digital experiences tailored to the specific
          goals of each client. Our approach combines creativity, innovation, and the latest technology
          to ensure that every project we undertake stands out in the digital landscape.
        </p>
        <br />
        <p>
          Our core values are centered around client satisfaction, quality, and continuous
          improvement. We work closely with our clients to understand their needs and provide
          solutions that exceed expectations. With a commitment to transparency, collaboration, and excellence, Erence Developers is your trusted partner in navigating the digital world.
        </p>
        <br />
        <p>
          From concept to launch, we take pride in being part of your journey.
          Whether you're a startup looking for a fresh, modern website or an established
          business in need of a digital makeover, Erence Developers is here to
          help you achieve your goals.
        </p>
        </div>
        </div>
      </div>
    
    </div>
  );
}

export default AboutUsMiddleSection
